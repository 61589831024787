import React, { useState } from "react";

import { 
    Row, 
    Col, 
    CardBody, 
    Card, 
    Container, 
    Form, 
    Input, 
    FormFeedback, 
    FormGroup,
    Alert
} from "reactstrap";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

import success from "../../../../assets/images/success.png";

import { 
    postOrgProvision
 } from "./../../../../helpers/backend_helper";

import withRouter from "Components/Common/withRouter";

const ProvisionOrganization = () => {
    const [step, setStep]:any = useState('org');
    const [apiError, setApiError]:any = useState();

    //meta title
    document.title = `Provision | NonprofitsHQ Admin`;


    async function provision(body:any) {

        try {
            let response;
            response = postOrgProvision(body);
            const data = await response;
            if(data) {
                setStep('complete');
            }
        } catch (error:any) {
            setApiError(error);
        }
    }

    // Form validation 
    const validation:any = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            organization_name: '',
            organization_tenant: '',
            organization_notifications_email: '',
            forming: '',
        },
        validationSchema: Yup.object({
            organization_name: Yup.string().required("Organization name is required."),
            organization_tenant: Yup.string().required("Organization tenant is required."),
            organization_notifications_email: Yup.string().required("Organization notification email is required."),
            forming: Yup.string().required("Organization legal status required for provisioning.")
        }),
        onSubmit: (values: any) => {
            provision(values);
        }
    });

    return (
        <React.Fragment>
        <div className="page-content">
            <Container fluid>
            <Row>
                <Col md={6}>
                <Card className="overflow-hidden">
                    <CardBody className="pt-10">
                        
                        <div className="p-2">
                            <Form className="form-horizontal"
                            onSubmit={(e) => {
                                e.preventDefault();
                                validation.handleSubmit();
                                return false;
                            }}
                            >
                            { step==='org' && (<Row>

                                {apiError && (<Alert style={{marginBottom: '30px'}} color="danger">{apiError}</Alert>)}

                                <p className="text-primary" style={{fontSize: '22px'}}>Provision: Organization</p>
                                <p style={{fontSize: '14px'}}>Complete information about the organization to provision a new account. All fields required.</p>


                                <Row style={{marginTop: '30px'}}>
                                    <p className="text-primary" style={{fontSize: '18px', fontWeight: '500'}}>Organization Name</p>
                                    <p style={{fontSize: '14px', fontWeight: '300'}}>This is the legal name of the nonprofit as recorded in the Articles of Incoporation and as recognized by the IRS. </p>

                                    <FormGroup className="mb-3">
                                        <Input
                                            name="organization_name"
                                            placeholder="Organization Name"
                                            type="text"
                                            className="form-control"
                                            id="organization_name"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.organization_name || ""}
                                            invalid={
                                                validation.touched.organization_name && validation.errors.organization_name ? true : false
                                            }
                                            style={{fontSize: '16px', padding: '10px'}}
                                        />
                                        {validation.touched.organization_name && validation.errors.organization_name ? (
                                            <FormFeedback type="invalid">{validation.errors.organization_name}</FormFeedback>
                                        ) : null}
                                    </FormGroup>
                                </Row>

                                <Row style={{marginTop: '30px'}}>
                                    <p className="text-primary" style={{fontSize: '18px', fontWeight: '500'}}>Organization Status</p>
                                    <p style={{fontSize: '14px', fontWeight: '300'}}>If the organization is legally formed in their state, choose established. Otherwise choose forming.</p>

                                    <FormGroup className="mb-3">
                                        <Input
                                            id="forming"
                                            name="forming"
                                            type={"select"}
                                            className="form-select"
                                            style={{fontSize: '16px', padding: '10px'}}
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.forming || ""}
                                            invalid={
                                                validation.touched.forming && validation.errors.forming ? true : false
                                            }
                                        >
                                            <option key={'empty'}>--</option>
                                            <option key="established" value={0}>Established</option>
                                            <option key="forming" value={1}>Forming</option>
                                        </Input>
                                        {validation.touched.forming && validation.errors.forming ? (
                                            <FormFeedback type="invalid">{validation.errors.forming}</FormFeedback>
                                        ) : null}
                                    </FormGroup>
                                </Row>

                                <Row style={{marginTop: '30px'}}>
                                    <p className="text-primary" style={{fontSize: '18px', fontWeight: '500'}}>Organization Tenant</p>
                                    <p style={{fontSize: '14px', fontWeight: '300'}}>This is the custom URL that will be used to access the NonprofitsHQ services. This tenant must be globally unique. </p>

                                    <FormGroup className="mb-3">
                                    <div className="input-group auth-pass-inputgroup">
                                        <Input
                                            width="100px"
                                            name="organization_tenant"
                                            placeholder="Organization Tenant"
                                            type="text"
                                            className="form-control"
                                            id="organization_tenant"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.organization_tenant || ""}
                                            invalid={
                                                validation.touched.organization_tenant && validation.errors.organization_tenant ? true : false
                                            }
                                            style={{fontSize: '16px', padding: '10px'}}
                                        />
                                        <select
                                            className="form-select"
                                            style={{fontSize: '16px', padding: '10px'}}
                                        >
                                        <option key={'empty'}>--</option>
                                            <option key={'nonprofitshq.com'} value={'nonprofitshq.org'}>.nonprofitshq.org</option>
                                        </select>
                                    </div>
                                    {validation.touched.organization_tenant && validation.errors.organization_tenant ? (
                                        <FormFeedback type="invalid">{validation.errors.organization_tenant}</FormFeedback>
                                    ) : null}
                                    </FormGroup>
                                </Row>

                                <Row style={{marginTop: '30px'}}>
                                    <p className="text-primary" style={{fontSize: '18px', fontWeight: '500'}}>Organization Email</p>
                                    <p style={{fontSize: '14px', fontWeight: '300'}}>The email address that all organization notifications should be sent to. If the orgnaization doesn't have a specific email for important notifications, use the Account Owner email.</p>

                                    <FormGroup className="mb-3">
                                        <Input
                                            name="organization_notifications_email"
                                            placeholder="Organization Notification Email"
                                            type="text"
                                            className="form-control"
                                            id="organization_notifications_email"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.organization_notifications_email || ""}
                                            invalid={
                                                validation.touched.organization_notifications_email && validation.errors.organization_notifications_email ? true : false
                                            }
                                            style={{fontSize: '16px', padding: '10px'}}
                                        />
                                        {validation.touched.organization_notifications_email && validation.errors.organization_notifications_email ? (
                                            <FormFeedback type="invalid">{validation.errors.organization_notifications_email}</FormFeedback>
                                        ) : null}
                                    </FormGroup>
                                </Row>

                                <Row style={{marginTop: '30px'}}>
                                    <Col md={6}> </Col>

                                    <Col md={6}>
                                        <button
                                            className="btn btn-primary btn-small"
                                            type="submit"
                                            style={{borderRadius: '10px', padding: '10px', fontSize: '14px', width: '100%'}}
                                        >
                                            Provision
                                        </button>
                                    </Col>
                                </Row>
                            </Row>)}

                        </Form>

                        { step === 'complete' && (<div className="p-2">
                            <Row>

                            <Col md={2}>
                                <img src={success} alt="NonprofitsHQ Trophy" style={{width: '70px'}} />
                            </Col>
                            
                            <Col md={10}>
                                <p style={{fontSize: '16px', fontWeight: '300'}}>Success! The new account has been provisioned and an email has been sent inviting the account owner to complete onboarding and account setup.</p>

                                <p style={{fontSize: '16px', fontWeight: '300'}}>Some things to remember:</p>

                                <ul>
                                    <li style={{fontSize: '16px', marginBottom: '20px'}}>It may take up to 48 hours for the tenant domain to propogate.</li>
                                    <li style={{fontSize: '16px', marginBottom: '20px'}}>As the Partner Success representative for this new organization, it is your responsibility to follow up with the account owner and ensure they have everything they need to be successful with NonprofitsHQ.</li>
                                </ul>

                                <button
                                    className="btn btn-primary btn-block btn-large"
                                    style={{borderRadius: '10px', padding: '10px', fontSize: '14px', marginTop: '20px', float: 'right'}}
                                    onClick={() => window.location.replace(`/organizations/provision`)}
                                >
                                    Provision Another
                                </button>
                            </Col>
                            </Row>
                        </div>)}

                    </div>
                    </CardBody>

                    <div className="mt-5 text-center">
                    <p> © {new Date().getFullYear()} NonprofitsHQ, Inc. All rights reserved.</p>
                </div>

                </Card>
                
                </Col>
            </Row>
            </Container>
        </div>
        </React.Fragment>
    );
};

export default withRouter(ProvisionOrganization);
