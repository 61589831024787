import { APIClient } from "./api_helper";

import * as url from "./url_helper";

const api = new APIClient();

// Gets the logged in user data from local session
export const getLoggedInUser = () => {
  const user = localStorage.getItem("user");
  if (user) return JSON.parse(user);
  return null;
};

// Register Method
export const postFakeRegister = (data: any) => {
  return api.create(url.POST_FAKE_REGISTER, data)
    .catch(err => {
      let message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message = "Sorry! something went wrong, please contact our support team";
            break;
          case 401:
            message = "Invalid credentials";
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
};

// Login Method
export const postLogin = (data: any) => api.create('admin/login', data);

// Get profile form /me
export const getUserProfile = () => api.get(url.API_ME, []);

// DASHBOARD
export const getDashboardData = () => api.get(`dashboard`, null);

// OTHER
export const getPlatformApps = () => api.get(`apps`, null);

// FUNDRAISING
export const getFundraisingTransactions = (data: any) => api.get(`organizations/${data.organization.id}/transactions`, null);
export const getFundraisingPayouts = (data: any) => api.get(`organizations/${data.organization.id}/payouts`, null);
export const getFundraisingCampaigns = (data: any) => api.get(`organizations/${data.organization.id}/campaigns`, null);
export const getFundraisingCampaign = (user: any, campaign_id: any) => api.get(`organizations/${user.organization.id}/campaigns/${campaign_id}`, null);
export const getPublicFundraisingCampaign = (campaign_id: any) => api.get(`donate/${campaign_id}`, null);
export const createDonationCheckout = (campaign: any, data:any) => api.create(`/organizations/${campaign?.organization?.id}/campaigns/${campaign?.id}/donation_checkout`, data);
export const createTicketCheckout = (data:any) => api.create(`/checkout/tickets`, data);

export const getFeesCampaignDetails = (user:any) => api.get(`organizations/${user?.organization?.id}/filing_fees`, null);
export const createLimitedCampaign = (user:any) => api.create(`organizations/${user?.organization?.id}/filing_fees`, null);
export const getCostBreakdown = (campaign_id: any) => api.get(`help/${campaign_id}`, null);

// TASKS
export const getUserTasks = (user:any) => api.get(`/users/${user?.id}/tasks`, null);

// EVENTS 
export const getEvents = (user:any) => api.get(`organizations/${user?.organization?.id}/events`, null);
export const getEvent = (user:any, event_id:any) => api.get(`organizations/${user?.organization?.id}/events/${event_id}`, null);
export const getPublicEvent = (event_id:any) => api.get(`/events/${event_id}`, null);
export const createEvent = (user:any, body:any) => api.create(`/organizations/${user?.organization?.id}/events`, body);

// SHIFTS
export const createEventShift = (user:any, body:any) => api.create(`organizations/${user?.organization?.id}/shifts`, body);
export const getShift = (user:any, shift_hash:any) => api.get(`organizations/${user?.organization?.id}/shifts/${shift_hash}`, null);
export const getPublicShift = (shift_hash:any) => api.get(`shifts/${shift_hash}`, null);
export const updateShift = (shift:any, body:any) => api.put(`organizations/${shift?.organization?.id}/shifts/${shift?.id}`, body);
export const updatePublicShift = (shift:any, body:any) => api.put(`shifts/${shift?.id}`, body);


// ONBOARDING
export const getOnboardingDetails = (hash:any) => api.get(`/onboarding/${hash}`, null);
export const getStripeOnboardingLink = () => api.get(`/identity`, null);
export const postToneChange = (query:any, tone:any, onboarding_hash:any) => api.create(`/tobi/tone`, {'query': query, 'tone': tone, 'onboarding_hash': onboarding_hash});
export const setUserInfo = (user:any, data:any) => api.put(`/onboarding/${user?.onboarding_hash}`, data);
export const postOnboardingData = (user:any, body:any) => api.create(`/onboarding/${user?.onboarding_hash}`, body);

//PEOPLE
export const getPeopleRoster = (data: any) => api.get(`organizations/${data.organization.id}/users`, null);
export const getDonorRoster = (data: any) => api.get(`organizations/${data.organization.id}/donors`, null);
export const getContactRoster = (data: any) => api.get(`organizations/${data.organization.id}/contacts`, null);
export const getContact = (user: any, contact_id: any) => api.get(`organizations/${user?.organization?.id}/contacts/${contact_id}`, null);
export const getUser = (user: any, people_id: any) => api.get(`organizations/${user?.organization?.id}/users/${people_id}`, null);
export const getDonor = (user: any, donor_id: any) => api.get(`organizations/${user?.organization?.id}/donors/${donor_id}`, null);
export const updateUser = (user:any, data:any) => api.put(`organizations/${user?.organization?.id}/users/${user?.id}`, data);
export const updateDonor = (user:any, data:any) => api.put(`organizations/${user?.organization?.id}/donors/${user?.id}`, data);
export const createUser = (user:any, body:any) => api.create(`/organizations/${user?.organization?.id}/users`, body);
export const createInvites = (user:any, body:any) => api.create(`/organizations/${user?.organization?.id}/invites`, body);

// POLICY
export const getPolicyDocuments = (data: any) => api.get(`organizations/${data.organization.id}/documents`, null);

// postForgetPwd
export const postFakeForgetPwd = (data: any) => api.create(url.POST_FAKE_PASSWORD_FORGET, data);

// Edit profile
export const postJwtProfile = (data: any) => api.create(url.POST_EDIT_JWT_PROFILE, data);

export const postFakeProfile = (data: any) => api.create(url.POST_EDIT_PROFILE, data);

// SCREENINGS
export const getScreeningPackages = () => api.get('screening_packages', null);
export const postScreeningOrder = (data:any, org_id:any) => api.create(`organizations/${org_id}/screenings`, data);


// Tobi
export const postTobiPrompt = (user: any, body: any) => api.create(`organizations/${user.organization.id}/helps`, body);
export const postGenerationInstruction = (user: any, body: any) => api.create(`tobi`, body);

// Register Method
export const postJwtRegister = (url: any, data: any) => {
  return api.create(url, data)
    .catch((err: any) => {
      var message: any;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message = "Sorry! something went wrong, please contact our support team";
            break;
          case 401:
            message = "Invalid credentials";
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
};

// Login Method
export const postJwtLogin = (data: any) => api.create(url.POST_FAKE_JWT_LOGIN, data);

// postForgetPwd
export const postJwtForgetPwd = (data: any) => api.create(url.POST_FAKE_JWT_PASSWORD_FORGET, data);


// ADMIN
export const getOrganizations = () => api.get(`/admin/organizations`, null);
export const postOrgProvision = (data:any) => api.create(`/admin/organizations`, data);

export const postUserProvision = (data:any) => api.create(`/admin/users`, data);
