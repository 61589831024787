import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';

import Breadcrumb from 'Components/Common/Breadcrumb';
import OrganizationsTable from './OrganizationsTable';
import { useSelector } from 'react-redux';
import { getOrganizations } from "../../../../helpers/backend_helper"

const Organizations = () => {
  document.title = "Organizations | NonprofitsHQ Admin";

  const [organizations, setOrganizations]:any = useState([]);

  const { user } = useSelector((state: any) => ({
    user: state.Profile.user
  }));

  async function getAllOrganizations() {
    try {
        let response;
        response = getOrganizations();
        const data = await response;
        if(data) {
            setOrganizations(data);
        }
    } catch (error) {
        console.log(error);
    }
  }

  useEffect(() => {
    getAllOrganizations();
  }, [user])
  

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb title="Admin" breadcrumbItem="Organizations" />

          <Row>
            <Col xl={12}>
              <OrganizationsTable organizations={organizations} />
            </Col>
            
          </Row>
        </Container>
      </div>
    </React.Fragment >
  );
}

export default Organizations;