import React, { useState, useEffect } from "react";

import { 
    Row, 
    Col, 
    CardBody, 
    Card, 
    Container, 
    Form, 
    Input, 
    FormFeedback, 
    FormGroup,
    Alert
} from "reactstrap";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

import success from "../../../../assets/images/success.png";

import { 
    getOrganizations,
    postUserProvision
 } from "./../../../../helpers/backend_helper";

import withRouter from "Components/Common/withRouter";

const ProvisionUser = () => {
    const [step, setStep]:any = useState('org');
    const [apiError, setApiError]:any = useState();
    const [organizations, setOrganizations]:any = useState();

    //meta title
    document.title = `Provision | NonprofitsHQ Admin`;


    async function provision(body:any) {

        try {
            let response;
            response = postUserProvision(body);
            const data = await response;
            if(data) {
                setStep('complete');
            }
        } catch (error:any) {
            setApiError(error);
        }
    }

    async function getAllOrganizations() {
        try {
            let response;
            response = getOrganizations();
            const data = await response;
            if(data) {
                setOrganizations(data);
            }
        } catch (error) {
            console.log(error);
        }
      }
    
      useEffect(() => {
        getAllOrganizations();
      }, [])

    // Form validation 
    const validation:any = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            first_name: '',
            last_name: '',
            organization_id: '',
            email: '',
        },
        validationSchema: Yup.object({
            first_name: Yup.string().required("First name is required."),
            last_name: Yup.string().required("Last name is required."),
            email: Yup.string().required("Email is required."),
        }),
        onSubmit: (values: any) => {
            provision(values);
        }
    });

    return (
        <React.Fragment>
        <div className="page-content">
            <Container fluid>
            <Row>
                <Col md={6}>
                <Card className="overflow-hidden">
                    <CardBody className="pt-10">
                        
                        <div className="p-2">
                            <Form className="form-horizontal"
                            onSubmit={(e) => {
                                e.preventDefault();
                                validation.handleSubmit();
                                return false;
                            }}
                            >
                            { step==='org' && (<Row>

                                {apiError && (<Alert style={{marginBottom: '30px'}} color="danger">{apiError}</Alert>)}

                                <p className="text-primary" style={{fontSize: '22px'}}>Provision: Account Owner</p>
                                <p style={{fontSize: '14px'}}>Create a new user and assign them to an organization as an account owner. This form will create a new user and a CRM contact record for the new account.</p>


                                <Row style={{marginTop: '30px'}}>

                                    <Col md={6}>
                                        <p className="text-primary" style={{fontSize: '16px', fontWeight: '450'}}>First Name</p>

                                        <FormGroup className="mb-3">
                                            <Input
                                                name="first_name"
                                                placeholder="First Name"
                                                type="text"
                                                className="form-control"
                                                id="first_name"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.first_name || ""}
                                                invalid={
                                                    validation.touched.first_name && validation.errors.first_name ? true : false
                                                }
                                                style={{fontSize: '16px', padding: '10px'}}
                                            />
                                            {validation.touched.first_name && validation.errors.first_name ? (
                                                <FormFeedback type="invalid">{validation.errors.first_name}</FormFeedback>
                                            ) : null}
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                    <p className="text-primary" style={{fontSize: '16px', fontWeight: '450'}}>Last Name</p>

                                        <FormGroup className="mb-3">
                                            <Input
                                                name="last_name"
                                                placeholder="Last Name"
                                                type="text"
                                                className="form-control"
                                                id="last_name"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.last_name || ""}
                                                invalid={
                                                    validation.touched.last_name && validation.errors.last_name ? true : false
                                                }
                                                style={{fontSize: '16px', padding: '10px'}}
                                            />
                                            {validation.touched.last_name && validation.errors.last_name ? (
                                                <FormFeedback type="invalid">{validation.errors.last_name}</FormFeedback>
                                            ) : null}
                                        </FormGroup>
                                    </Col>
                                    
                                </Row>

                                <Row style={{marginTop: '30px'}}>
                                    <p className="text-primary" style={{fontSize: '16px', fontWeight: '450'}}>Email</p>

                                    <FormGroup className="mb-3">
                                        <Input
                                            name="email"
                                            placeholder="Email"
                                            type="text"
                                            className="form-control"
                                            id="email"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.email || ""}
                                            invalid={
                                                validation.touched.email && validation.errors.email ? true : false
                                            }
                                            style={{fontSize: '16px', padding: '10px'}}
                                        />
                                        {validation.touched.first_name && validation.errors.email ? (
                                            <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                                        ) : null}
                                    </FormGroup>
                                    
                                </Row>

                                <Row style={{marginTop: '30px'}}>
                                    <p className="text-primary" style={{fontSize: '16px', fontWeight: '500'}}>Organization</p>
                                    <p style={{fontSize: '14px', fontWeight: '300'}}><Alert color="danger"><b>WARNING:</b> Adding an account owner give the user full access to every part of the NonprofitsHQ account.</Alert></p>

                                    <FormGroup className="mb-3">
                
                                        <Input 
                                            type='select'
                                            style={{fontSize: '16px'}}
                                            id="organization_id"
                                            className="form-select"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.organization_id}
                                            invalid={
                                                validation.touched.organization_id && validation.errors.organization_id ? true : false
                                            }
                                        >
                                            <option >---</option>
                                            { organizations?.map((org) => (
                                                <option key={org?.id} value={org?.id}>{ org?.name }</option>
                                            ))}
                                        </Input>
                                        {validation.touched.organization_id && validation.errors.organization_id ? (
                                            <FormFeedback type="invalid">{validation.errors.organization_id}</FormFeedback>
                                        ) : null}
                                    </FormGroup>
                                </Row>

                                
                                <Row style={{marginTop: '30px'}}>
                                    <Col md={6}> </Col>

                                    <Col md={6}>
                                        <button
                                            className="btn btn-primary btn-small"
                                            type="submit"
                                            style={{borderRadius: '10px', padding: '10px', fontSize: '14px', width: '100%'}}
                                        >
                                            Provision
                                        </button>
                                    </Col>
                                </Row>
                            </Row>)}
                        </Form>

                        { step === 'complete' && (<div className="p-2">
                            <Row>

                            <Col md={2}>
                                <img src={success} alt="NonprofitsHQ Trophy" style={{width: '70px'}} />
                            </Col>
                            
                            <Col md={10}>
                                <p style={{fontSize: '16px', fontWeight: '300'}}>Success! The user has been provisioned as an account owner for the specified organization.</p>

                                <p style={{fontSize: '16px', fontWeight: '300'}}>Some things to remember:</p>

                                <ul>
                                    <li style={{fontSize: '16px', marginBottom: '20px'}}>An email was sent to the new user inviting them to setup their account and organization.</li>
                                </ul>

                                <button
                                    className="btn btn-primary btn-block btn-large"
                                    style={{borderRadius: '10px', padding: '10px', fontSize: '14px', marginTop: '20px', float: 'right'}}
                                    onClick={() => window.location.replace(`/users/provision`)}
                                >
                                    Provision Another
                                </button>
                            </Col>
                            </Row>
                        </div>)}

                    </div>
                    </CardBody>

                    <div className="mt-5 text-center">
                    <p> © {new Date().getFullYear()} NonprofitsHQ, Inc. All rights reserved.</p>
                </div>

                </Card>
                
                </Col>
            </Row>
            </Container>
        </div>
        </React.Fragment>
    );
};

export default withRouter(ProvisionUser);
